import React from 'react'
import NavbarMain from '../components/NavbarMain'
import HeroSection from '../components/HeroSection'
import Values from '../components/Values'
import Mission from '../components/Mission'
import AboutSection from '../components/AboutSection'
import CarrierType from '../components/CarrierType'
import FeedBack from '../components/Feedback'
import Footer from '../components/Footer'

const About = () => {
  return (
    <>
     <NavbarMain/>
     <HeroSection />
     <Values />
     <Mission />
     <div className='mt-5'>
     <AboutSection />
     </div>
     <CarrierType />
     <FeedBack />
     <Footer />
    </>
  )
}

export default About
