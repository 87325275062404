import React from 'react'
import NavbarMain from '../components/NavbarMain'
import HeroSection from '../components/HeroSection'
import Footer from '../components/Footer'
import { FaMarker } from 'react-icons/fa'
import { IoLocation } from 'react-icons/io5'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { IoMdMail } from 'react-icons/io'

const Contact = () => {
  return (
    <>
      <NavbarMain />
      <HeroSection/>
      <section class="ftco-section-con">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-6 text-center mb-5">
					<h2 class="heading-section-con">Feel Free To Contact Us</h2>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-10 col-md-12">
					<div class="wrapper-con">
						<div class="row justify-content-center">
							<div class="col-lg-8 mb-5">
								<div class="row">
									<div class="col-md-4">
										<div class="dbox w-100 text-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
                        <IoLocation size={20} color='white' />
					        		</div>
					        		<div class="text">
						            <p><span>Address:</span> 
                      7901 4th St N STE 17418, St Petersburg, FLORIDA 33702</p>
						          </div>
					          </div>
									</div>
									<div class="col-md-4">
										<div class="dbox w-100 text-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<BsFillTelephoneFill size={20} color='white' />
					        		</div>
					        		<div class="text">
						            <p><span>Phone:</span> <a href="tel://1234567920">+ 1235 2355 98</a></p>
						          </div>
					          </div>
									</div>
									<div class="col-md-4">
										<div class="dbox w-100 text-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<IoMdMail size={20} color='white' />
					        		</div>
					        		<div class="text">
						            <p><span>Email:</span> <a href="mailto:support@enabledispatch.com">support@enabledispatch.com</a></p>
						          </div>
					          </div>
									</div>
								</div>
							</div>
							<div class="col-lg-8">
								<div class="contact-wrap text-white">
									<h3 class="mb-4 text-center">Get in touch with us</h3>
									<div id="form-message-warning" class="mb-4 w-100 text-center"></div> 
				      		<div id="form-message-success" class="mb-4 w-100 text-center">
				            Your message was sent, thank you!
				      		</div>
									<form method="POST" id="contactForm" name="contactForm" class="contactForm-con">
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<input type="text" class="form-control-con" name="name" id="name" placeholder="Name"/>
												</div>
											</div>
											<div class="col-md-12"> 
												<div class="form-group">
													<input type="email" class="form-control-con" name="email" id="email" placeholder="Email"/>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<input type="text" class="form-control-con" name="subject" id="subject" placeholder="Subject"/>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<textarea name="message" class="form-control-con" id="message" cols="30" rows="8" placeholder="Message"></textarea>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<input type="submit" value="Send Message" class="btn-con btn-con-primary"/>
													<div class="submitting"></div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  <div id="map-container-section" className="container" style={{ width:"100%" , margin:"20px auto"}}>
          <iframe
            title="Google Maps"
            src="https://maps.google.com/maps?q=7901 4th St N STE 17418, St Petersburg, FLORIDA 33702&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            className="w-100"
            style={{height:"400px" ,  borderRadius:"8px"}}
          />
        </div>
  <Footer/>
    </>
  )
}

export default Contact
