import React from "react";

const Values = () => {
  return (
    <>
      <section className="ftco-section" style={{ background: "#000" }}>
        <div className="container section-title-container">
          <h2 className="section-title section-title-bold-center">
            <b></b>
            <span
              className="section-title-main"
              style={{ color: "rgb(255, 255, 255)" }}
            >
              Our Core Values
            </span>
            <b></b>
          </h2>
        </div>
        <div className="container">
          <div className="row bg-dark">
            <div className="col-md-4 rexting">
              <div className="services services-2 w-100 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  {/* <span className="flaticon-wedding-car"></span> */}
                  <img
                    src="img/Values/enforcement.png"
                    alt="icon"
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="text w-100">
                  <h3 className="heading mb-2">Integrity</h3>
                  <p>Professionalism and honesty are our trademarks.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 rexting">
              <div className="services services-2 w-100 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  {/* <span className="flaticon-transportation"></span> */}
                  <img
                    src="img/Values/emotional-intelligence.png"
                    alt="icon"
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="text w-100">
                  <h3 className="heading mb-2">Attitude</h3>
                  <p>
                    Our team’s success is driven by the way they rise to
                    challenges with drive and determination. The way we react to
                    any situation is the way we will be remembered.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 rexting">
              <div className="services services-2 w-100 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  {/* <span className="flaticon-car"></span> */}
                  <img
                    src="img/Values/discipline.png"
                    alt="icon"
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="text w-100">
                  <h3 className="heading mb-2">Discipline</h3>
                  <p>
                  In everything we do. We strive to deliver on all of our commitments.
                  </p>
                </div>
              </div>
            </div>
            </div>
            </div>
            
      </section>
    </>
  );
};

export default Values;
