import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage,ref } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyD3xhR5xW9htdbzJI7zfTXrAsSle-tvKes",
  authDomain: "enable-dispatch.firebaseapp.com",
  projectId: "enable-dispatch",
  storageBucket: "enable-dispatch.appspot.com",
  messagingSenderId: "29312193926",
  appId: "1:29312193926:web:59cf6225334db2de318807",
  measurementId: "G-ZG36KR7GS4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const storageRef = ref