import React, { useEffect } from 'react'
import NabarMain from './NavbarMain'
import "../assets/Form1.css";
import Footer from './Footer';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <NabarMain />
            <main id="main" className="aux-main aux-territory  aux-single aux-page aux-full-container aux-content-top-margin no-sidebar aux-sidebar-style-border aux-user-entry" style={{ margin: "20vh auto" , alignSelf:"center" }}>
                <div className="aux-wrapper">
                    <div className="aux-container aux-fold">
                        <div id="primary" className="aux-primary">
                            <div className="content" role="main">
                                <article id="post-357" className="post-357 page type-page status-publish hentry">
                                    <div className="entry-main">
                                        <div className="entry-content">
                                            <div data-elementor-type="wp-page" data-elementor-id="357" className="elementor elementor-357" style={{ background: "#ECECEC", padding: "4%", borderRadius: "8px" }}>
                                                <div className="elementor-element elementor-element-a6ac156 e-con-boxed e-con" data-id="a6ac156" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}">
                                                    <div className="e-con-inner">
                                                        <div className="elementor-element elementor-element-3df6e65 elementor-widget elementor-widget-heading" data-id="3df6e65" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">

                                                                <h2 className="elementor-heading-title elementor-size-default">Terms and Conditions</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-611f29b e-con-boxed e-con" data-id="611f29b" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}">
                                                    <div className="e-con-inner">
                                                        <div className="elementor-element elementor-element-a4952dd elementor-widget elementor-widget-text-editor" data-id="a4952dd" data-element_type="widget" data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container">

                                                                <p>
                                                                    1. CARRIER agrees to pay the agreed fee for dispatching<br />
                                                                    2. CARRIER gives DISPATCHER authority to provide his/her signature for rate confirmation sheets, invoices, and associated paperwork necessary for securing cargo and billing purposes. The terms of this agreement shall be perpetual, provided that either party may terminate the same by giving 30 days written notice to the other.<br />The carrier agrees to pay the dispatcher promptly, following receipt of a freight bill and proof of delivery of each shipment to its assigned destination, free of damage or shortage. The amount to be paid by Broker to CARRIER shall be established between parties on a per-shipment basis prior to the commencement of each individual shipment. A load confirmation including details of shipment and revenue to be paid will be supplied via FAX or EMAIL by BROKER to CARRIER. Confirmation will be signed by DISPATCHER and returned via FAX or EMAIL by Broker
                                                                </p>
                                                                <p>
                                                                    <strong>
                                                                        OBLIGATIONS OF DISPATCHER<br />
                                                                    </strong>
                                                                    1. DISPATCHER agrees to handle paperwork, phone, and fax calls to, from the broker or SHIPPER to tender commodities shipments to CARRIER for transportation in interstate commerce by CARRIER between points and places within the scope of CARRIER’S operating authority.<br />
                                                                    2. DISPATCHER bears no financial or legal responsibility in the transaction between the shippers, and CARRIER agreement.<br />
                                                                    3.DISPATCHER will:<br />
                                                                    a.Make 100% effort to keep truck(s) loaded.<br />
                                                                    b.CARRIER will be contacted about EVERY load we find to offer, and the driver will ACCEPT or REJECT the load. Invoice the CARRIER at the time of service; also provide a copy of each Load Confirmation SheetCARRIER I am being billed for.<br />C. Dispatcher agrees to keep 24/7 monitoring of CARRIER’s dedicated online Portal and keep Drivers, Brokers, and portal updated with upcoming possibilities And ongoing situations which carrier is going through.
                                                                </p>
                                                                <p>
                                                                    Payments are due to the DISPATCHER for services rendered and payments that are due to the DISPATCHER for services rendered are not Contingent on Outstanding company payments due to the CARRIER for loads that he/she has hauled for the SHIPPER OR BROKER.<br />Failure to pay the DISPATCHER for services in 15 days rendered will result in the termination of the contract and services immediately unless otherwise determined by the DISPATCHER. And Legal actions would be taken
                                                                </p>
                                                                <p>
                                                                    I. NOTICE. This legal document grants you CARRIER/PRINCIPAL the right to transfer limited financial powers to someone else (Hereinafter referred to as the “Attorney-in-Fact”), limited financial powers are described as any specific financial act legal under the law. The Principal’s transfer of limited financial powers to the Attorney-in-Fact is granted upon authorization of this agreement and ONLY remains in effect until the completion of the said act unless the Principal becomes incapacitated (incapacitation is described in Paragraph II). This agreement does not authorize the Attorney-in-Fact to make medical decisions for the Principal. The Principal continues to retain every right to all their financial decision-making power and may revoke this Limited Power of Attorney Form at any time. The Principal may include restrictions or requests pertaining to the financial decision-making power of the Attorney-in-Fact. It is the intent of the Attorney-in-Fact to act in<br />
                                                                    the Principal’s wishes put forth, or, to make financial decisions that fit the Principal’s best interest. All parties authorizing this agreement must be at least 18 years of age and acting under no false pressures or outside influences. Upon authorization of this Limited Power of Attorney Form, it will revoke any previously valid Limited Power of Attorney Form.<br />
                                                                    II.INCAPACITATION. The powers granted to the Attorney-in-Fact by the Principal in this Limited Power of attorney Form DO NOT stay in effect upon incapacitation by the Principal, incapacitation is described as: Amedical physician stating verbally or in writing that the Principal can no longer make decisions for themself.<br />III.REVOCATION. The Principal has the right to revoke this Limited Power of Attorney Form at any time. Any revocation will be effective if Principal: A. authorizes a new Limited Power of Attorney Form. B. Authorizes a Power of Attorney Revocation Form.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                        {/* <!-- end article section --> */}
                                        <footer className="entry-meta"></footer>
                                        {/* <!-- end article footer --> */}
                                    </div>
                                </article>
                                {/* <!-- end article --> */}
                                <div className="clear"></div>
                                <div className="clear"></div>
                                {/* <!-- You can start editing here. --> */}
                            </div>
                            {/* <!-- end content --> */}
                        </div>
                        {/* <!-- end primary --> */}
                    </div>
                    {/* <!-- end container --> */}
                </div>
                {/* <!-- end wrapper --> */}
            </main>
            <Footer />
        </>
    )
}

export default Terms
