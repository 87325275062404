import React from 'react'
import "../assets/Navbar.css"
import { BsEnvelope } from "react-icons/bs";

const Mission = () => {
  return (
    <>
     <section
        className="section dark"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "rgb(0,0,0)",
        }}
      >
        <div className="bg section-bg fill bg-fill bg-loaded bg-loaded"></div>
        <div className="section-content relative">
          <div
            className="d-block d-lg-flex d-md-flex"
          >
            <div  className="col-12 col-lg-6 col-md-6">
              <div className="p-5 mt-lg-5">
                <h2 className="text-center text-white">Mission</h2>
                <p className="text-center text-white">
                  To revolutionize the transportation industry , inspiring our team 
                  to improve day by day to provide the highest level of trust to our 
                  customers
                   &nbsp;
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div id="col-734737714" className="col-12 col-lg-6 col-md-6">
              <div className="col-inner">
                <div
                  className="img has-hover x md-x lg-x y md-y lg-y"
                >
                  <div className="img-inner dark">
                    <img
                    style={{width:"100%"}}
                      src="img/Mission.jpg"
                      className="attachment-large size-large"
                      alt="Amazing truck dispatching."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "rgb(0,0,0)",
        }}
      >
        <div className="bg section-bg fill bg-fill bg-loaded bg-loaded"></div>
        <div className="section-content relative">
          <div
            className="d-block d-lg-flex d-md-flex"
          >
            <div id="col-151993412" className="col-12 col-lg-6 col-md-6">
              <div className="col-inner">
                <div
                  className="img has-hover x md-x lg-x y md-y lg-y"
                  id="image_428260237"
                  >
                  <div className="img-inner dark">
                    <img
                  style={{ width: "100%" }}
                      src="img/Vision.jpg"
                      className="attachment-large size-large"
                      alt="we can get you the best paying loads out of the midwest"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="col-1554792773" className="col-12 col-lg-6 col-md-6">
              <div className="p-5 mt-lg-5 text-center">
                <h2 className="text-center text-white">Vision</h2>
                <p className="text-center text-white">
                    To work deeply on our organizational culture , expand our coverage and 
                    diversity our services. Achieving results that add value to our 
                    customers. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default Mission
