import React from 'react'


const SeviceSec = () => {
  return (
    <section className="ftco-section" style={{background:"#000"}}>
        <div className="container section-title-container">
          <h2 className="section-title section-title-bold-center">
            <b></b>
            <span className="section-title-main" style={{color:"rgb(255, 255, 255)"}}>
            Our Latest Services
            </span>
            <b></b>
          </h2>
        </div>
    <div className="container">
      {/* <div className="row justify-content-center mb-5">
        <div className="col-md-7 text-center heading-section">
          <span className="subheading fs-1">Services</span>
          <h2 className="mb-3">Our Latest Services</h2>
        </div>
      </div> */}
      <div className="row bg-dark">
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-wedding-car"></span> */}
              <img
                src="img/shipment.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Truck Dispatching</h3>
              <p>
                Your knowledgeable truck dispatchers connect you with
                shippers, brokers, and factories directly. We complete all
                required documentation.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-transportation"></span> */}
              <img
                src="img/negotiation.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Rate Negotiations</h3>
              <p>
                We bargain for the highest paying prices for your cargoes.
                Because we are aware that Your trucking business is
                essential to the smooth running of our society and to the
                movement of America.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-car"></span> */}
              <img
                src="img/documentation.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Truck Invoice</h3>
              <p>
                Your clients benefit greatly from your hauling services. You
                have the knowledge and resources necessary to meet their
                special requirements for the delivery and transportation of
                their goods.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-transportation"></span> */}
              <img
                src="img/customer-support.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Customer Service</h3>
              <p>
                We are here to assist you manage your relationships with
                shippers and deal with problems relating to cargoes, such as
                lumper, detention, and layover. Also providing our devoted
                customers with 24/7 service
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-transportation"></span> */}
              <img
                src="img/accounting.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Trucking Accounts</h3>
              <p>
                Since we operate for carriers and act as a liaison between
                your Truck and the freight for your job, we, the
                dispatchers, act as a neutral third party between the
                carriers and brokers.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 rexting">
          <div className="services services-2 w-100 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="flaticon-transportation"></span> */}
              <img
                src="img/documents.png"
                alt="icon"
                style={{ width: "150px" }}
              />
            </div>
            <div className="text w-100">
              <h3 className="heading mb-2">Truck Documents</h3>
              <p>
                We take our commitment to offering the best management and
                services seriously. We strive to be the reliable supplier
                that trucking companies like yours require. The key
                components of our document trucker management services are
                shown below.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default SeviceSec
