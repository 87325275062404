import React from 'react'
import NavbarMain from '../components/NavbarMain'
import HeroSection from '../components/HeroSection'
import AboutSection from '../components/AboutSection'
import ProDispatch from '../components/ProDispatch'
import CarrierType from '../components/CarrierType'
import SeviceSec from '../components/SeviceSec'
import Counter from '../components/Counter'
import Feedback from '../components/Feedback'
import Footer from '../components/Footer'
import Pricing from '../components/Pricing'

const Home = () => {
  return (
    <>
    <NavbarMain/>
    <HeroSection />
    <AboutSection />
    <ProDispatch />
    <CarrierType />
    <Pricing />
    <SeviceSec />
    <Counter/>
    <Feedback />
    <Footer />
    </>
  )
}

export default Home
