import React, { useEffect, useRef, useState } from "react";
import NavbarMain from "../components/NavbarMain";
import "../assets/Form1.css";
import { set,child,ref } from 'firebase/database';
import {db,storage,storageRef} from '../FirebaseConfig/Firebase'
import { uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
const SetupCarrier = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // Form States
  const [CompanyName, setCompanyName] = useState("");
  const [Address1, setAddress1] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("AL");
  const [Zipcode, setZipcode] = useState("");
  const [Phone, setPhone] = useState("");
  const [MC, setMC] = useState("");
  const [USDOT, setUSDOT] = useState("");
  const [FEINSSN, setFEINSSN] = useState("");
  const [LikeToPay, setLikeToPay] = useState("");
  const [NumTrucks, setNumTrucks] = useState("");
  const [NumDrivers, setNumDrivers] = useState("");
  const [EquipmentType, setEquipmentType] = useState("");
  const [FactorInvoices, setFactorInvoices] = useState(false);
  const [TermsConditions, setTermsConditions] = useState("");
  const [PreferToDrive, setPreferToDrive] = useState("");

  // show % or Flat Fee
  // 1st PDF Upload 
  const [FirstPdf, setFirstPdf] = useState(null);
  
  const inputFileFirst = useRef(null);
  // to open file explore
  const openInputFirstPDF = (e) => {
    inputFileFirst.current.click();
  };
  // to set
  const handleFirstPDF = (e) => {
    inputFileFirst.current.click();
    setFirstPdf(e.target.files[0])
    }

  // 2nd PDF Upload 
  const [SecoundPdf, setSecoundPdf] = useState(null);
  const inputFileSecound = useRef(null);
  // to open file explore
  const openInputSecoundPDF = (e) => {
    inputFileSecound.current.click();
  };
  // to set
  const handleSecoundPDF = (e) => {
    inputFileSecound.current.click();
    setSecoundPdf(e.target.files[0])
    }

  // 3rd PDF Upload 
  const [ThirdPdf, setThirdPdf] = useState(null);
  const inputFileThird = useRef(null);
  // to open file explore
  const openInputThirdPDF = (e) => {
    inputFileThird.current.click();
  };
  // to set
  const handleThirdPDF = (e) => {
    inputFileThird.current.click();
    setThirdPdf(e.target.files[0])
  }
  // 3rd PDF Upload 
  const [FourthPdf, setFourthPdf] = useState(null);
  const inputFileFourth = useRef(null);
  // to open file explore
  const openInputFourthPDF = (e) => {
    inputFileFourth.current.click();
  };
  // to set
  const handleFourthPDF = (e) => {
    inputFileFourth.current.click();
    setFourthPdf(e.target.files[0])
  }

  const handleSubmit=async ()=>{
    const uuid = Math.floor(Math.random()*900) + 100;
    if (!FirstPdf || !SecoundPdf || !ThirdPdf){
      window.alert("please selected all pdf")
      return
    };
    const firstpdf = storageRef(storage, `disptach/${uuid}/${FirstPdf.name}`);
    uploadBytes(firstpdf, FirstPdf).then(async ()=>{
      const firstpdfdownloadURL = await getDownloadURL(firstpdf);
      const secondpdf = storageRef(storage,  `disptach/${uuid}/${SecoundPdf.name}`);
      uploadBytes(secondpdf, SecoundPdf).then(async ()=>{
        const secondpdfdownloadURL = await getDownloadURL(secondpdf);
        const thirdpdf = storageRef(storage,  `disptach/${uuid}/${ThirdPdf.name}`);
        uploadBytes(thirdpdf, ThirdPdf).then(async ()=>{
          const thirdpdfdownloadURL = await getDownloadURL(thirdpdf)
        const fourthpdf = storageRef(storage,  `disptach/${uuid}/${FourthPdf.name}`);
        uploadBytes(fourthpdf, FourthPdf).then(async ()=>{
          const fourthpdfdownloadURL = await getDownloadURL(fourthpdf)
          const data = {
            companyname:CompanyName,
            address1:Address1,
            city:City,
            state:State,
            zipcode:Zipcode,
            phone:Phone,
            mc:MC,
            usdot:USDOT,
            feinssn:FEINSSN,
            liketopay:LikeToPay,
            numtrucks:NumTrucks,
            numdrivers:NumDrivers,
            equipmenttype:EquipmentType,
            factorinvoices:FactorInvoices,
            perfertodrive:PreferToDrive,
            firstpdf:firstpdfdownloadURL,
            secondpdf:secondpdfdownloadURL,
            thirdpdf:thirdpdfdownloadURL,
            fourthpdf:fourthpdfdownloadURL?fourthpdfdownloadURL:null,
          }
          const database = ref(db, `dispatch/${uuid}`);
          set(database, data)
          .then(() => {
            window.alert('Data set successfully!')
            window.location.reload()
          })
          .catch((error) => {
            window.alert('Error setting data: ', error.message);
          });
        })

      })

    })
    })
    
  }
  return (
    <>
      <NavbarMain />
      <main
        id="main"
        className="aux-main aux-territory  aux-single aux-page aux-full-container aux-content-top-margin no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-149"
                  className="post-149 page type-page status-publish hentry"
                >
                  <div className="entry-main">
                    <div className="entry-content">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="149"
                        className="elementor elementor-149"
                      >
                        <div
                          className="elementor-element elementor-element-083f4b0 e-con-boxed e-con"
                          data-id="083f4b0"
                          data-element_type="container"
                          data-settings='{"content_width":"boxed"}'
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-d27bcf3 elementor-widget elementor-widget-heading"
                              data-id="d27bcf3"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default text-white">
                                  Carrier Setup
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="elementor-element elementor-element-180f598 e-con-boxed e-con"
                          data-id="180f598"
                          data-element_type="container"
                          data-settings='{"content_width":"boxed"}'
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-9414218 elementor-widget elementor-widget-wpforms"
                              data-id="9414218"
                              data-element_type="widget"
                              data-widget_type="wpforms.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  className="wpforms-container wpforms-container-full"
                                  id="wpforms-338"
                                >
                                  <div
                                    id="wpforms-form-338"
                                    className="wpforms-validate wpforms-form wpforms-ajax-form"
                                  >
                                    <div className="wpforms-field-container">
                                      <div
                                        id="wpforms-338-field_9-container"
                                        className="wpforms-field wpforms-field-text"
                                        data-field-id="9"
                                      >
                                        <label className="wpforms-field-label">
                                          Company Name
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="wpforms-field-large wpforms-field-required"
                                          value={CompanyName}
                                          onChange={(e) =>
                                            setCompanyName(e.target.value)
                                          }
                                        />
                                      </div>
                                      
                                      <div
                                        id="wpforms-338-field_27-container"
                                        className="wpforms-field wpforms-field-address"
                                        data-field-id="27"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_27"
                                        >
                                          Address{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <div className="wpforms-field-row wpforms-field-medium">
                                          <div>
                                            <input
                                              type="text"
                                              id="wpforms-338-field_27"
                                              className="wpforms-field-address-address1 wpforms-field-required"
                                              name="wpforms[fields][27][address1]"
                                              value={Address1}
                                              onChange={(e) =>
                                                setAddress1(e.target.value)
                                              }
                                              required
                                            />
                                            <label
                                              htmlFor="wpforms-338-field_27"
                                              className="wpforms-field-sublabel after "
                                            >
                                              Address Line 1
                                            </label>
                                          </div>
                                        </div>
                                        <div className="wpforms-field-row wpforms-field-medium">
                                          <div className="wpforms-field-row-block wpforms-one-half wpforms-first">
                                            <input
                                              type="text"
                                              id="wpforms-338-field_27-city"
                                              className="wpforms-field-address-city wpforms-field-required"
                                              name="wpforms[fields][27][city]"
                                              value={City}
                                              onChange={(e) =>
                                                setCity(e.target.value)
                                              }
                                              required
                                            />
                                            <label
                                              htmlFor="wpforms-338-field_27-city"
                                              className="wpforms-field-sublabel after "
                                            >
                                              City
                                            </label>
                                          </div>
                                          <div className="wpforms-field-row-block wpforms-one-half">
                                            <select
                                              id="wpforms-338-field_27-state"
                                              className="wpforms-field-address-state wpforms-field-required"
                                              name="wpforms[fields][27][state]"
                                              value={State}
                                              onChange={(e) =>
                                                setState(e.target.value)
                                              }
                                              required
                                            >
                                              <option value="AL">
                                                Alabama
                                              </option>
                                              <option value="AK">Alaska</option>
                                              <option value="AZ">
                                                Arizona
                                              </option>
                                              <option value="AR">
                                                Arkansas
                                              </option>
                                              <option value="CA">
                                                California
                                              </option>
                                              <option value="CO">
                                                Colorado
                                              </option>
                                              <option value="CT">
                                                Connecticut
                                              </option>
                                              <option value="DE">
                                                Delaware
                                              </option>
                                              <option value="DC">
                                                District of Columbia
                                              </option>
                                              <option value="FL">
                                                Florida
                                              </option>
                                              <option value="GA">
                                                Georgia
                                              </option>
                                              <option value="HI">Hawaii</option>
                                              <option value="ID">Idaho</option>
                                              <option value="IL">
                                                Illinois
                                              </option>
                                              <option value="IN">
                                                Indiana
                                              </option>
                                              <option value="IA">Iowa</option>
                                              <option value="KS">Kansas</option>
                                              <option value="KY">
                                                Kentucky
                                              </option>
                                              <option value="LA">
                                                Louisiana
                                              </option>
                                              <option value="ME">Maine</option>
                                              <option value="MD">
                                                Maryland
                                              </option>
                                              <option value="MA">
                                                Massachusetts
                                              </option>
                                              <option value="MI">
                                                Michigan
                                              </option>
                                              <option value="MN">
                                                Minnesota
                                              </option>
                                              <option value="MS">
                                                Mississippi
                                              </option>
                                              <option value="MO">
                                                Missouri
                                              </option>
                                              <option value="MT">
                                                Montana
                                              </option>
                                              <option value="NE">
                                                Nebraska
                                              </option>
                                              <option value="NV">Nevada</option>
                                              <option value="NH">
                                                New Hampshire
                                              </option>
                                              <option value="NJ">
                                                New Jersey
                                              </option>
                                              <option value="NM">
                                                New Mexico
                                              </option>
                                              <option value="NY">
                                                New York
                                              </option>
                                              <option value="NC">
                                                North Carolina
                                              </option>
                                              <option value="ND">
                                                North Dakota
                                              </option>
                                              <option value="OH">Ohio</option>
                                              <option value="OK">
                                                Oklahoma
                                              </option>
                                              <option value="OR">Oregon</option>
                                              <option value="PA">
                                                Pennsylvania
                                              </option>
                                              <option value="RI">
                                                Rhode Island
                                              </option>
                                              <option value="SC">
                                                South Carolina
                                              </option>
                                              <option value="SD">
                                                South Dakota
                                              </option>
                                              <option value="TN">
                                                Tennessee
                                              </option>
                                              <option value="TX">Texas</option>
                                              <option value="UT">Utah</option>
                                              <option value="VT">
                                                Vermont
                                              </option>
                                              <option value="VA">
                                                Virginia
                                              </option>
                                              <option value="WA">
                                                Washington
                                              </option>
                                              <option value="WV">
                                                West Virginia
                                              </option>
                                              <option value="WI">
                                                Wisconsin
                                              </option>
                                              <option value="WY">
                                                Wyoming
                                              </option>
                                            </select>
                                            <label
                                              htmlFor="wpforms-338-field_27-state"
                                              className="wpforms-field-sublabel after "
                                            >
                                              State
                                            </label>
                                          </div>
                                        </div>
                                        <div className="wpforms-field-row wpforms-field-medium">
                                          <div className="wpforms-field-row-block wpforms-one-half wpforms-first">
                                            <input
                                              type="text"
                                              id="wpforms-338-field_27-postal"
                                              className="wpforms-field-address-postal wpforms-field-required wpforms-masked-input"
                                              data-inputmask-mask="(99999)|(99999-9999)"
                                              data-inputmask-keepstatic="true"
                                              data-rule-inputmask-incomplete="1"
                                              name="wpforms[fields][27][postal]"
                                              value={Zipcode}
                                              onChange={(e) =>
                                                setZipcode(e.target.value)
                                              }
                                              required
                                            />
                                            <label
                                              htmlFor="wpforms-338-field_27-postal"
                                              className="wpforms-field-sublabel after "
                                            >
                                              Zip Code
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        id="wpforms-338-field_17-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="17"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_17"
                                        >
                                          Phone{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_17"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][17]"
                                          value={Phone}
                                          onChange={(e) =>
                                            setPhone(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_19-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="19"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_19"
                                        >
                                          MC#{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_19"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][19]"
                                          value={MC}
                                          onChange={(e) =>
                                            setMC(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_20-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="20"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_20"
                                        >
                                          USDOT#{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_20"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][20]"
                                          value={USDOT}
                                          onChange={(e) =>
                                            setUSDOT(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_21-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="21"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_21"
                                        >
                                          FEIN/SSN{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_21"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][21]"
                                          value={FEINSSN}
                                          onChange={(e) =>
                                            setFEINSSN(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_68-container"
                                        className="wpforms-field wpforms-field-checkbox"
                                        data-field-id="68"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_68"
                                        >
                                          How would you like to pay us?{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <ul
                                          id="wpforms-338-field_68"
                                          className="wpforms-field-required"
                                          value={LikeToPay}
                                          onChange={(e) =>
                                            setLikeToPay(e.target.value)
                                          }
                                        >
                                          <li className="choice-1 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_68_1"
                                              name="wpforms[fields][68][]"
                                              value="ACH (Direct Deposit)"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_68_1"
                                            >
                                              ACH (Direct Deposit)
                                            </label>
                                          </li>
                                          <li className="choice-2 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_68_2"
                                              name="wpforms[fields][68][]"
                                              value="Wire"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_68_2"
                                            >
                                              Wire
                                            </label>
                                          </li>
                                          <li className="choice-3 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_68_3"
                                              name="wpforms[fields][68][]"
                                              value="Credit/Debit Card"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_68_3"
                                            >
                                              Credit/Debit Card
                                            </label>
                                          </li>
                                        </ul>
                                      </div>

                                      <div
                                        id="wpforms-338-field_22-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="22"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_22"
                                        >
                                          Number Of Trucks?{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_22"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][22]"
                                          value={NumTrucks}
                                          onChange={(e) =>
                                            setNumTrucks(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_23-container"
                                        className="wpforms-field wpforms-field-number"
                                        data-field-id="23"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_23"
                                        >
                                          Number Of Drivers?{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          pattern="\d*"
                                          id="wpforms-338-field_23"
                                          className="wpforms-field-large wpforms-field-required"
                                          name="wpforms[fields][23]"
                                          onChange={(e) =>
                                            setNumDrivers(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_24-container"
                                        className="wpforms-field wpforms-field-radio"
                                        data-field-id="24"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_24"
                                        >
                                          Type Of Equipment?{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <ul
                                          id="wpforms-338-field_24"
                                          className="wpforms-field-required"
                                          value={EquipmentType}
                                          onChange={(e) =>
                                            setEquipmentType(e.target.value)
                                          }
                                        >
                                          <li className="choice-1 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_24_1"
                                              name="wpforms[fields][24]"
                                              value=" Dry Van"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_24_1"
                                            >
                                              Dry Van
                                            </label>
                                          </li>
                                          <li className="choice-2 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_24_2"
                                              name="wpforms[fields][24]"
                                              value=" Reefer"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_24_2"
                                            >
                                              Reefer
                                            </label>
                                          </li>
                                          <li className="choice-3 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_24_3"
                                              name="wpforms[fields][24]"
                                              value=" Flatbed/Step-deck"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_24_3"
                                            >
                                              Flatbed/Step-deck
                                            </label>
                                          </li>
                                          <li className="choice-5 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_24_5"
                                              name="wpforms[fields][24]"
                                              value="Power Only"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_24_5"
                                            >
                                              Power Only
                                            </label>
                                          </li>
                                          <li className="choice-6 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_24_6"
                                              name="wpforms[fields][24]"
                                              value="Hot Shot"
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_24_6"
                                            >
                                              Hot Shot
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        id="wpforms-338-field_25-container"
                                        className="wpforms-field wpforms-field-checkbox wpforms-conditional-trigger"
                                        data-field-id="25"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_25"
                                        >
                                          Do you factor your invoices?{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <ul
                                          id="wpforms-338-field_25"
                                          className="wpforms-field-required"
                                          // onChange={(e) =>
                                          //   setFactorInvoices(e.target.value)
                                          // }
                                  
                                        >
                                          <li className="choice-1 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_25_1"
                                              name="wpforms[fields][25][]"
                                              value={true}
                                              onChange={(e) =>
                                                setFactorInvoices(true)
                                              }
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_25_1"
                                            >
                                              Yes
                                            </label>
                                          </li>
                                          <li className="choice-2 depth-1">
                                            <input
                                              type="radio"
                                              id="wpforms-338-field_25_2"
                                              name="wpforms[fields][25][]"
                                              value={false}
                                              onChange={(e) =>
                                                setFactorInvoices(false)
                                              }
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline"
                                              htmlFor="wpforms-338-field_25_2"
                                            >
                                              No
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        id="wpforms-338-field_66-container"
                                        className="wpforms-field wpforms-field-checkbox"
                                        data-field-id="66"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_66"
                                        >
                                          Do you agree to terms and conditions
                                          of Company{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <ul
                                          id="wpforms-338-field_66"
                                          className="wpforms-field-required"
                                          value={TermsConditions}
                                          onChange={(e) =>
                                            setTermsConditions(e.target.value)
                                          }
                                        >
                                          <li className="choice-1 depth-1 d-flex">
                                            <input
                                              type="checkbox"
                                              id="wpforms-338-field_66_1"
                                              name="wpforms[fields][66][]"
                                              value='I Agree to the &lt;a title="Terms &amp; Condition" href="" target="_blank" rel="noopener"&gt;Terms &amp; Condition&lt;/a&gt; of the company'
                                              required
                                            />
                                            <label
                                              className="wpforms-field-label-inline d-flex"
                                              htmlFor="wpforms-338-field_66_1"
                                            >
                                              I Agree to the{" "}
                                              <p className="text-primary" style={{cursor:"pointer"}} onClick={() => navigate("/terms-and-conditions")}>
                                              &nbsp;Terms &amp;Condition &nbsp;

                                              </p>{" "}
                                              of the company
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        id="wpforms-338-field_30-container"
                                        className="wpforms-field wpforms-field-file-upload"
                                        data-field-id="30"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_30"
                                        >
                                          MC Authority{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <div
                                          className="wpforms-uploader"
                                          onClick={openInputFirstPDF}
                                          onChange={handleFirstPDF}
                                          style={{
                                            backgroundColor: "#fff",
                                            padding: "30px 10px",
                                            borderRadius: 8,
                                            textAlign: "center",
                                            cursor:"pointer"
                                          }}
                                        >
                                          <div className="dz-message">
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              focusable="false"
                                              data-icon="inbox"
                                              width="50px"
                                              height="50px"
                                              fill="#b1b1b1"
                                              aria-hidden="true"
                                            >
                                              <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                                            </svg>
                                            <span className="modern-title">
                                              Click Here To Upload File
                                            </span>
                                          </div>
                                        </div>
                                        <input
                                          type="file"
                                          id="file"
                                          className="rounded-circle photouser"
                                          ref={inputFileFirst}
                                          onClick={openInputFirstPDF}
                                          onChange={handleFirstPDF}
                                          accept="application/pdf application/docx application/doc"
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_31-container"
                                        className="wpforms-field wpforms-field-file-upload"
                                        data-field-id="31"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_31"
                                        >
                                          W9-Form
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <div
                                          className="wpforms-uploader"
                                          onClick={openInputSecoundPDF}
                                          onChange={handleSecoundPDF}
                                          style={{
                                            backgroundColor: "#fff",
                                            padding: "30px 10px",
                                            borderRadius: 8,
                                            textAlign: "center",
                                            cursor:"pointer"
                                          }}
                                        >
                                          <div className="dz-message">
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              focusable="false"
                                              data-icon="inbox"
                                              width="50px"
                                              height="50px"
                                              fill="#b1b1b1"
                                              aria-hidden="true"
                                            >
                                              <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                                            </svg>
                                            <span className="modern-title">
                                              Click Here To Upload File
                                            </span>
                                          </div>
                                        </div>
                                        <input
                                          type="file"
                                          id="file"
                                          className="rounded-circle photouser"
                                          ref={inputFileSecound}
                                          onClick={openInputSecoundPDF}
                                          onChange={handleSecoundPDF}
                                          accept="application/pdf application/docx application/doc"
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      <div
                                        id="wpforms-338-field_29-container"
                                        className="wpforms-field wpforms-field-file-upload"
                                        data-field-id="29"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_29"
                                        >
                                          Certificate of Insurance{" "}
                                          <span className="wpforms-required-label">
                                            *
                                          </span>
                                        </label>
                                        <div
                                          className="wpforms-uploader"
                                          onClick={openInputThirdPDF}
                                          onChange={handleThirdPDF}
                                          style={{
                                            backgroundColor: "#fff",
                                            padding: "30px 10px",
                                            borderRadius: 8,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div className="dz-message">
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              focusable="false"
                                              data-icon="inbox"
                                              width="50px"
                                              height="50px"
                                              fill="#b1b1b1"
                                              aria-hidden="true"
                                            >
                                              <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                                            </svg>
                                            <span className="modern-title">
                                              Click Here To Upload File
                                            </span>
                                          </div>
                                        </div>
                                        <input
                                          type="file"
                                          id="file"
                                          className="rounded-circle photouser"
                                          ref={inputFileThird}
                                          onClick={openInputThirdPDF}
                                          onChange={handleThirdPDF}
                                          accept="application/pdf application/docx application/doc"
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      {FactorInvoices == true ? (
                                        <div
                                          id="wpforms-338-field_29-container"
                                          className="wpforms-field wpforms-field-file-upload"
                                          data-field-id="29"
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-338-field_29"
                                          >
                                            Notice of Assignment{" "}
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <div
                                           onClick={openInputFourthPDF}
                                           onChange={handleFourthPDF}
                                            className="wpforms-uploader"
                                            data-extensions="jpg,jpeg,jpe,gif,png,bmp,tiff,tif,webp,ico,heic,asf,asx,wmv,wmx,wm,avi,divx,mov,qt,mpeg,mpg,mpe,mp4,m4v,ogv,webm,mkv,3gp,3gpp,3g2,3gp2,txt,asc,c,cc,h,srt,csv,tsv,ics,rtx,css,vtt,mp3,m4a,m4b,aac,ra,ram,wav,ogg,oga,flac,mid,midi,wma,wax,mka,rtf,pdf,class,tar,zip,gz,gzip,rar,7z,psd,xcf,doc,pot,pps,ppt,wri,xla,xls,xlt,xlw,mpp,docx,docm,dotx,dotm,xlsx,xlsm,xlsb,xltx,xltm,xlam,pptx,pptm,ppsx,ppsm,potx,potm,ppam,sldx,sldm,onetoc,onetoc2,onepkg,oxps,xps,odt,odp,ods,odg,odc,odb,odf,wp,wpd,key,numbers,pages,svg"
                                            style={{
                                              backgroundColor: "#fff",
                                              padding: "30px 10px",
                                              borderRadius: 8,
                                              textAlign: "center",
                                            }}
                                          >
                                            <div className="dz-message">
                                              <svg
                                                viewBox="0 0 1024 1024"
                                                focusable="false"
                                                data-icon="inbox"
                                                width="50px"
                                                height="50px"
                                                fill="#b1b1b1"
                                                aria-hidden="true"
                                              >
                                                <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                                              </svg>
                                              <span className="modern-title">
                                                Click Here To Upload File
                                              </span>
                                            </div>
                                          </div>
                                          <input
                                             type="file"
                                             id="file"
                                             className="rounded-circle photouser"
                                             ref={inputFileFourth}
                                             onClick={openInputFourthPDF}
                                             onChange={handleFourthPDF}
                                             accept="application/pdf application/docx application/doc"
                                             style={{ display: "none" }}
                                          />
                                        </div>
                                      ) : null}
                                      <div
                                        id="wpforms-338-field_26-container"
                                        className="wpforms-field wpforms-field-textarea"
                                        data-field-id="26"
                                      >
                                        <label
                                          className="wpforms-field-label"
                                          htmlFor="wpforms-338-field_26"
                                        >
                                          What States Do You Prefer To Drive?
                                        </label>
                                        <textarea
                                          id="wpforms-338-field_26"
                                          className="wpforms-field-medium"
                                          name="wpforms[fields][26]"
                                          onChange={(e) => setPreferToDrive(e.target.value)}
                                          value={PreferToDrive}
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="wpforms-submit-container">
                                      <input
                                        type="hidden"
                                        name="wpforms[id]"
                                        value="338"
                                      />
                                      <input
                                        type="hidden"
                                        name="wpforms[author]"
                                        value="1"
                                      />
                                      <input
                                        type="hidden"
                                        name="wpforms[post_id]"
                                        value="149"
                                      />
                                      <button
                                        onClick={()=>handleSubmit()}
                                        onSubmit={()=>handleSubmit()}
                                        type="submit"
                                        name="wpforms[submit]"
                                        id="wpforms-submit-338"
                                        className="wpforms-submit"
                                        data-alt-text="Sending..."
                                        data-submit-text="Submit"
                                        aria-live="assertive"
                                        value="wpforms-submit"
                                      >
                                        Submit
                                      </button>
                                      {/* <img
                                        decoding="async"
                                        src="https://360dispatchingservices.com/wp-content/plugins/wpforms/assets/images/submit-spin.svg"
                                        className="wpforms-submit-spinner"
                                        width="26"
                                        height="26"
                                        alt="Loading"
                                      /> */}
                                    </div>
                                  </div>
                                  {/* </form> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SetupCarrier;
