import React from 'react'
import { BsFillPlayFill } from "react-icons/bs";
import sample from '../assets/truck.mp4'
import "../assets/Navbar.css";
import { useNavigate } from 'react-router-dom';



const HeroSection = () => {
    const prams = window.location.pathname;
    const navigate = useNavigate()
    const size = window.innerWidth
  console.log(size,"size")
  return (
    <>
      <div
        className="hero-wrap ftco-degree-bg"
        style={{ backgroundImage: prams == "/"? "": prams == "/about" ? 
          "url('img/aboutTrucks.jpg')" :
          prams == "/services" ?
           "url('img/ServicesBg.jpg')" :
           "url('img/ContactUs.jpg')" }}
      >
        <div className="overlay"></div>
        <video className='videoTag'style={{zIndex:-1 , position:"absolute" , width:"100%",height:size<992?"100%":"100vh", objectFit:"cover", objectPosition:"70% 70%" }} autoPlay loop muted>
    <source src={sample} type='video/mp4' />
</video>
          <div className="container" >
            <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
              <div className="col-lg-8">
                {prams == "/" ?
                  <div className="text w-100 text-center mb-md-5 pb-md-5" >
                    <h1 className="mb-4">
                    Your Trusted Partner for Efficient Truck Dispatching.
                    </h1>
                    <p style={{ fontSize: "18px" }}>
                    Experience seamless, round-the-clock support for your transportation business with our dedicated team of truck dispatch experts. Take your operations to new heights with Enable Dispatch – your reliable partner.
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      Ready to join Enable Dispatch family? Submit your application.
                    </p>
                    <div className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                      <div className="icon d-flex align-items-center justify-content-center" style={{cursor:"pointer"}} onClick={() => navigate("/setupCarrier")}>
                        <BsFillPlayFill color="white" size={26}  />
                      </div>
                      <div className="heading-title ml-5">
                        <span>Register Your Carrier Setup Now</span>
                      </div>
                    </div>
                  </div>
                  : prams == "/whyenable" ? <div className="text w-100 text-center mb-md-5 pb-md-5">
                    <h1 className="mb-4">
                      Why People Choose Us
                    </h1>
                    <p style={{ fontSize: "18px", lineHeight: "29px", }}>
                      Enable Dispatch offers comprehensive dispatching and load management services designed
                      to make your life easier while you’re on the road. <br />
                      It offers exceptional support, which can help you build quality relationships
                      with clients and set the stage for ongoing growth.
                      <br />
                      Enable Dispatch provides services for owner-operators, large fleets and any size business in between.
                    </p>
                    <div className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                      <div className="icon d-flex align-items-center justify-content-center" style={{cursor:"pointer"}} onClick={() => navigate("/setupCarrier")}>
                        <BsFillPlayFill color="white" size={26}  />
                      </div>
                      <div className="heading-title ml-5">
                        <span>Register Your Carrier Setup Now</span>
                      </div>
                    </div>
                  </div>
                    :prams == "/services" ? <div className="text w-100 text-center mb-md-5 pb-md-5">
                      <h1 className="mb-4">
                        Services
                      </h1>
                      <p style={{ fontSize: "18px", lineHeight: "29px", }}>
                        Help you with Rate Negotiations, Truck Dispatching, Customer Service, and more.
                        <br />
                        Enable Dispatch provides services for owner-operators, large fleets and any size business in between.
                      </p>
                      <div className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                      <div className="icon d-flex align-items-center justify-content-center" style={{cursor:"pointer"}} onClick={() => navigate("/setupCarrier")}>
                        <BsFillPlayFill color="white" size={26}  />
                      </div>
                      <div className="heading-title ml-5">
                        <span>Register Your Carrier Setup Now</span>
                      </div>
                    </div>
                    </div> : <div className="text w-100 text-center mb-md-5 pb-md-5">
                      <h1 className="mb-4">
                        Contact Us
                      </h1>
                      <p style={{ fontSize: "25px", lineHeight: "29px", }}>
                      If you require any further information, please feel free to contact us.
                          <br /> <br />
                          We’ll be pleased to answer any further queries you might have.
                      </p>
                      <div className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                      <div className="icon d-flex align-items-center justify-content-center" style={{cursor:"pointer"}} onClick={() => navigate("/setupCarrier")}>
                        <BsFillPlayFill color="white" size={26}  />
                      </div>
                      <div className="heading-title ml-5">
                        <span>Register Your Carrier Setup Now</span>
                      </div>
                    </div>
                    </div>
                }
              </div>
            </div>
          </div>
      </div> 
    </>
  )
}

export default HeroSection
