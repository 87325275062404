import React from 'react'
import NavbarMain from '../components/NavbarMain'
import HeroSection from '../components/HeroSection'
import ProDispatch from '../components/ProDispatch'
import ServiceSec from '../components/SeviceSec'
import Counter from '../components/Counter'
import Footer from '../components/Footer'
import Feedback from '../components/Feedback'
import Pricing from '../components/Pricing'

const Services = () => {
  return (
    <>
      <NavbarMain />
      <HeroSection />
      <ProDispatch />
      <ServiceSec />
      <Pricing />
      <Counter />
      <Feedback />
      <Footer />
    </>
  )
}

export default Services
