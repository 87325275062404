import React from "react";
import "../assets/Form1.css";
import "../assets/Pricing.css";
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate()
  return (
    <>
      <main
        id="main"
        className="aux-main aux-territory  aux-single aux-page aux-full-container aux-content-top-margin no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-155"
                  className="post-155 page type-page status-publish hentry"
                >
                  <div className="entry-main">
                    <div className="entry-content">
                      <div className="elementor elementor-155">
                        <div className="container section-title-container">
                          <h2 className="section-title section-title-bold-center">
                            <b></b>
                            <span
                              className="section-title-main"
                              style={{ color: "rgb(255, 255, 255)" }}
                            >
                              Pricing
                            </span>
                            <b></b>
                          </h2>
                        </div>
                        <div className="row">
                          <div className="col-0 col-md-0 col-lg-2"></div>
                          <div className="col-0 col-md-0 col-lg-8">
                            <div className="elementor-element elementor-element-2af4e52 e-con-boxed e-con">
                              <div className="row">
                                <div
                                  className="elementor-element elementor-element-d51f88a e-con-full e-con col-md-4 col-lg-4 col-12"
                                  data-id="d51f88a"
                                  data-element_type="container"
                                  data-settings='{"content_width":"full"}'
                                >
                                  <div
                                    className="elementor-element elementor-element-ee2ded9 e-transform e-transform elementor-widget elementor-widget-price-table"
                                    data-id="ee2ded9"
                                    data-element_type="widget"
                                    data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1,"sizes":[]},"_transform_scale_effect":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_mobile":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                                    data-widget_type="price-table.default"
                                  >
                                    <div
                                      style={{ borderRadius: "10px" }}
                                      className="elementor-widget-container mainCard"
                                    >
                                      <div className="elementor-price-table">
                                        <div className="elementor-price-table__header bg-light">
                                          <h3
                                            className=""
                                            style={{
                                              textDecoration: "overline",
                                              fontSize: "31px",
                                              fontWeight: 600,
                                              color: "#060000",
                                            }}
                                          >
                                            Basic{" "}
                                          </h3>
                                        </div>
                                        <div className="elementor-price-table__price">
                                          <span className="elementor-price-table__currency">
                                            %
                                          </span>
                                          <span className="elementor-price-table__integer-part">
                                            4{" "}
                                          </span>
                                          <span className="elementor-price-table__period elementor-typo-excluded">
                                            /Per Load
                                          </span>
                                        </div>
                                        <ul className="elementor-price-table__features-list">
                                          <li className="elementor-repeater-item-0b527fb">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Negotiate Best Rates </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-57a59b5">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Carrier Packet Setup </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-0ba4399">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>
                                                Sign & Secure Rate Confirmation{" "}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="elementor-price-table__footer">
                                          <button onClick={() => navigate("/setupCarrier")} className="btn mainCard mainCardBtn" style={{border:"none", boxShadow:"none" , padding:"10px 20px"  , color:"white" }}>
                                            Get Started
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-e1592a3 e-con-full e-con col-md-4 col-lg-4 col-12"
                                  data-id="e1592a3"
                                  data-element_type="container"
                                  data-settings='{"content_width":"full"}'
                                >
                                  <div
                                    className="elementor-element elementor-element-5b341b5 e-transform e-transform elementor-widget elementor-widget-price-table"
                                    data-id="5b341b5"
                                    data-element_type="widget"
                                    data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1,"sizes":[]},"_transform_scale_effect":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_mobile":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                                    data-widget_type="price-table.default"
                                  >
                                    <div
                                      style={{ borderRadius: "10px" }}
                                      className="elementor-widget-container mainCard"
                                    >
                                      <div className="elementor-price-table">
                                        <div className="elementor-price-table__header bg-light">
                                        <h3
                                            className=""
                                            style={{
                                              textDecoration: "overline",
                                              fontSize: "31px",
                                              fontWeight: 600,
                                              color: "#060000",
                                            }}
                                          >
                                            Premium{" "}
                                          </h3>
                                        </div>
                                        <div className="elementor-price-table__price">
                                          <span className="elementor-price-table__currency">
                                            %
                                          </span>
                                          <span className="elementor-price-table__integer-part">
                                            7{" "}
                                          </span>
                                          <span className="elementor-price-table__period elementor-typo-excluded">
                                            /Per Load
                                          </span>
                                        </div>
                                        <ul className="elementor-price-table__features-list">
                                          <li className="elementor-repeater-item-0b527fb">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Negotiate Best Rates </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-57a59b5">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Carrier Packet Setup </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-0ba4399">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>
                                                Sign & Secure Rate Confirmation{" "}
                                              </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-5fab7d8">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Personal Dispatcher </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-fb83895">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>
                                                TONU/Detention Assistance{" "}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="elementor-price-table__footer">
                                          <button onClick={() => navigate("/setupCarrier")} className="btn mainCard mainCardBtn" style={{border:"none", boxShadow:"none" , padding:"10px 20px"  , color:"white" }}>
                                            Get Started
                                          </button>
                                        </div>
                                      </div>
                                      <div className="elementor-price-table__ribbon">
                                        <div className="elementor-price-table__ribbon-inner text-white ">
                                          Popular
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-a22b012 e-con-full e-con col-md-4 col-lg-4 col-12"
                                  data-id="a22b012"
                                  data-element_type="container"
                                  data-settings='{"content_width":"full"}'
                                >
                                  <div
                                    className="elementor-element elementor-element-212ea54 e-transform e-transform elementor-widget elementor-widget-price-table"
                                    data-id="212ea54"
                                    data-element_type="widget"
                                    data-settings='{"_transform_scale_effect_hover":{"unit":"px","size":1.1,"sizes":[]},"_transform_scale_effect":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_mobile":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_tablet":{"unit":"px","size":"","sizes":[]},"_transform_scale_effect_hover_mobile":{"unit":"px","size":"","sizes":[]}}'
                                    data-widget_type="price-table.default"
                                  >
                                    <div
                                      style={{ borderRadius: "10px" }}
                                      className="elementor-widget-container mainCard"
                                    >
                                      <div className="elementor-price-table">
                                        <div className="elementor-price-table__header bg-light">
                                        <h3
                                            className=""
                                            style={{
                                              textDecoration: "overline",
                                              fontSize: "31px",
                                              fontWeight: 600,
                                              color: "#060000",
                                            }}
                                          >
                                            Standard{" "}
                                          </h3>
                                        </div>
                                        <div className="elementor-price-table__price">
                                          <span className="elementor-price-table__currency">
                                            %
                                          </span>
                                          <span className="elementor-price-table__integer-part">
                                            5{" "}
                                          </span>
                                          <span className="elementor-price-table__period elementor-typo-excluded">
                                            /Per Load
                                          </span>
                                        </div>
                                        <ul className="elementor-price-table__features-list">
                                          <li className="elementor-repeater-item-0b527fb">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Negotiate Best Rates </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-57a59b5">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Carrier Packet Setup </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-0ba4399">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>
                                                Sign & Secure Rate Confirmation{" "}
                                              </span>
                                            </div>
                                          </li>
                                          <li className="elementor-repeater-item-5fab7d8">
                                            <div
                                              className="elementor-price-table__feature-inner"
                                              style={{
                                                borderBottom:
                                                  "2px solid #ececec",
                                                margin: "0px 10%",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <span>Personal Dispatcher </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="elementor-price-table__footer">
                                          <button onClick={() => navigate("/setupCarrier")} className="btn mainCard mainCardBtn" style={{border:"none", boxShadow:"none" , padding:"10px 20px"  , color:"white" }}>
                                            Get Started
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-8e6b090 e-con-boxed e-con"
                              data-id="8e6b090"
                              data-element_type="container"
                              data-settings='{"content_width":"boxed"}'
                            >
                              <div className="e-con-inner">
                                <div
                                  className="elementor-element elementor-element-66e0dbd elementor-widget elementor-widget-spacer"
                                  data-id="66e0dbd"
                                  data-element_type="widget"
                                  data-widget_type="spacer.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-de5d266 e-con-boxed e-con"
                              data-id="de5d266"
                              data-element_type="container"
                              data-settings='{"content_width":"boxed"}'
                            >
                              <div className="e-con-inner"></div>
                            </div>
                          </div>
                          <div className="col-0 col-md-0 col-lg-2"></div>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </div>
                    {/* <!-- end article section --> */}
                    <footer className="entry-meta"></footer>
                    {/* <!-- end article footer --> */}
                  </div>
                </article>
                {/* <!-- end article --> */}
                <div className="clear"></div>
                <div className="clear"></div>
                {/* <!-- You can start editing here. --> */}
              </div>
              {/* <!-- end content --> */}
            </div>
            {/* <!-- end primary --> */}
          </div>
          {/* <!-- end container --> */}
        </div>
        {/* <!-- end wrapper --> */}
      </main>
    </>
  );
};

export default Pricing;
