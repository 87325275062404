import React, { useState } from "react";
import "../assets/Navbar.css";

const CarrierType = () => {
  const [type, setType] = useState(1);
  const size = window.innerWidth
  return (
    <>
      <section
        className="sectiontype dark"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "black",
        }}
      >
        <div className="bg section-bg fill bg-fill bg-loaded bg-loaded">
          <div
            className="section-bg-overlay absolute fill"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.73)" }}
          ></div>
        </div>
        <div className="section-content relative">
          <div
            className="gap-element clearfix"
            style={{ display: "block", height: "auto", paddingTop: "30px" }}
          ></div>
          <div className="tabbed-content text-light">
            <h4 className="uppercase text-center text-uppercase fs-2 pb-4">
              Carrier Type
            </h4>
            <ul
              className="nav nav-line-grow nav-uppercase nav-size-normal nav-center d-flex justify-content-center "
              role="tablist"
            >
              <li
                id="tab-night-dispatch"
                className={`tab ${
                  type === 1 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(1)}
              >
                <span className="text-light">Reefer</span>
              </li>
              <li
                id="tab-tow-dispatch"
                className={`tab ${
                  type === 2 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(2)}
              >
                <span className="text-light">Step Deck</span>
              </li>
              <li
                id="tab-car-hauler"
                className={`tab ${
                  type === 3 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(3)}
              >
                <span className="text-light">Hot Shot</span>
              </li>
              <li
                id="tab-owner-operator"
                className={`tab ${
                  type === 4 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(4)}
              >
                <span className="text-light">Dry Van</span>
              </li>
              <li
                id="tab-trucking-fleet"
                className={`tab ${
                  type == 5 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(5)}
              >
                <span className="text-light">Power Only</span>
              </li>
              <li
                id="tab-trucking-fleet"
                className={`tab ${
                  type == 6 ? "active" : ""
                } has-icon px-4 py-2`}
                role="presentation"
                onClick={() => setType(6)}
              >
                <span className="text-light">Flat bed</span>
              </li>
            </ul>
            <div className="tab-panels">
              <div
                id="tab_night-dispatch"
                className="panel active entry-content py-5"
                role="tabpanel"
                aria-labelledby="tab-night-dispatch"
              >
                <div
                  className="banner has-hover has-parallax py-5"
                  id="banner-1737554888"
                  style={{
                    backgroundImage:
                      type === 1
                      ? "url('img/truck/Reefer3.jpeg'),linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))"
                      : type === 2
                      ? "url('img/truck/step-deck3.jpg')"
                      : type === 3
                      ? "url('img/truck/HotShot3.png'),linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))"
                      : type === 4
                      ? "url('img/Pro2.jpg'),linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))"
                      :type === 5? "url('img/truck/PowerOnly.webp')"
                      :"url('img/truck/flatbed.jpeg')",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY:size>992?"bottom":"center",
                    backgroundPositionX:size>992?"bottom":"center",
                    backgroundSize: size>992?"cover":"contain",
                    height: size>992?"500px":"170px",
                    backgroundBlendMode: "overlay"
                  }}
                >
                  <div className="banner-inner fill">
                    <div
                      className="banner-bg fill"
                      data-parallax="-3"
                      data-parallax-container=".banner"
                      data-parallax-background
                    >
                      <div
                        className="bg fill bg-fill bg-loaded"
                        style={{ backgroundPosition: "0% 62%" }}
                      ></div>
                      <div
                        className="overlay"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.66)" }}
                      ></div>
                    </div>

                    <div className="banner-layers container d-flex justify-content-center">
                      <div className="fill banner-link"></div>

                      <div
                        id="text-box-38772573"
                        className="text-box banner-layer x50 md-x50 lg-x50 y50 md-y50 lg-y50 res-text"
                        style={{ width: "60%" }}
                      >
                        <div className="text-box-content text dark">
                          {/* <div className="text-inner text-center">
                            <h2 className="text-center d-none d-md-block py-sm-5">
                              {type === 1
                                ? "WHY CUSTOMERS LOVE US"
                                : type == 2
                                ? "DROP DECK TRAILER"
                                : type == 3
                                ? "FLATBED TRAILERS"
                                : type == 4
                                ? "COMPLETELY ENCLOSED AND SEALED TRAILERS"
                                : "STRAIGHT TRUCK"}
                            </h2>
                            <p className="text-center" >
                              <strong>
                                {type === 1
                                  ? "Reefer trailers, alternatively referred to as refrigerated trailers, are a variety of semi-trailer specifically engineered to uphold a precise temperature range, ensuring the preservation of perishable cargo's freshness and quality during transit. This particular trailer is outfitted with a refrigeration mechanism capable of sustaining a temperature spectrum generally ranging from -20 to 70 degrees Fahrenheit (-29 to 21 degrees Celsius), contingent upon the cargo's specific needs."
                                  : type == 2
                                  ? "A step deck trailer, alternatively referred to as a drop deck trailer, is a specific type of semi-trailer that features a lowered deck in the rear section. This distinctive design enables the transportation of taller cargo with greater ease. Unlike a regular flatbed truck with a single-level flat deck, the step deck trailer provides the capacity to accommodate cargo that exceeds the height limitations of a standard flatbed."
                                  : type == 3
                                  ? "Hotshot trucking pertains to a specialized transportation service that focuses on the efficient delivery of smaller and time-sensitive loads within specific timeframes. These hotshot loads are typically transported using flatbed trailers that are towed behind a truck."
                                  : type == 4
                                  ? `Dry van truckload shipping is a widely utilized transportation service that encompasses the transportation of cargo using fully enclosed and sealed trailers, commonly referred to as dry vans. These trailers are specifically designed to transport a diverse range of goods, including packaged consumer goods, electronics, and raw materials.`
                                  : "A straight truck, also referred to as a box truck, is a commercial motor vehicle extensively utilized for the transportation of goods. It features a single container that spans the distance between each axle, extending from the front of the cab to the rear of the trailer."}
                              </strong>
                            </p>
                            <p className="text-center d-none d-lg-block ">
                              <strong>
                                {type == 1
                                  ? "Reefer trailers are widely employed for the transportation of perishable commodities, encompassing fruits, vegetables, dairy products, meat, seafood, and pharmaceuticals. The temperature regulation within reefer trailers serves to decelerate the degradation process and prolong the longevity of the cargo throughout its transit, thereby extending its shelf life."
                                  : type == 2
                                  ?"The drop deck configuration of the step deck trailer offers an extra level of clearance to accommodate taller cargo. The cargo is loaded onto the elevated section of the trailer and subsequently lowered onto the lower deck section, which is typically positioned at a height of approximately 2 feet from the ground. This design enhances the efficiency of loading and unloading procedures while minimizing the potential for cargo damage during transportation."
                                  : type == 3
                                  ?"Hotshot trucking is frequently employed when the size of the load does not warrant the expense of a full-size trailer, yet it is too sizable or time-sensitive to be shipped through traditional parcel delivery services. Hotshot drivers commonly operate as owner-operators, owning and operating their own truck and trailer, or they may work for a small trucking company that specializes in providing this specific service."
                                  : type == 4
                                  ?"Dry van truckload shipping services are employed in situations where it is crucial to safeguard the cargo from adverse weather conditions, theft, or damage during transportation. The enclosed nature of dry van trailers provides a protective barrier against environmental factors, ensuring the integrity of the cargo. Additionally, the sealed design of these trailers acts as a deterrent to unauthorized access, further enhancing the security of the cargo throughout its journey."
                                  : "traight trucks are available in various sizes, spanning from small vehicles capable of carrying a few thousand pounds to larger counterparts capable of transporting several tons of cargo. They are frequently utilized for local deliveries, such as furniture moving or package delivery, but can also serve for long-haul transportation purposes."}
                              </strong>
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarrierType;
