import React from "react";
import "../assets/Navbar.css"
import { BsEnvelope } from "react-icons/bs";

const ProDispatch = () => {
  return (
    <>
      <div className="container section-title-container">
        <h2 className="section-title section-title-bold-center">
          <b></b>
          <span className="section-title-main" style={{color:"rgb(255, 255, 255)"}}>
            Professional truck dispatching
          </span>
          <b></b>
        </h2>
      </div>

      <p>&nbsp;</p>
      <section
        className="section dark"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "rgb(0,0,0)",
        }}
      >
        <div className="bg section-bg fill bg-fill bg-loaded bg-loaded"></div>
        <div className="section-content relative">
          <div
            className="d-block d-lg-flex d-md-flex"
          >
            <div  className="col-12 col-lg-6 col-md-6">
              <div className="p-5 mt-lg-5">
                <h2 className="text-center text-white">Quality Communication</h2>
                <p className="text-center text-white">
                  Our dispatchers work to find you the best freight for Dry Van,
                  Reefer and Flatbed. We offer both daytime
                  operations as well night time, 3rd Shift Dispatch. All of our
                  dispatchers go through a 3-month training before working
                  directly with drivers! Our focus is on excellent communication
                  on behalf of the client that we represent.&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div id="col-734737714" className="col-12 col-lg-6 col-md-6">
              <div className="col-inner">
                <div
                  className="img has-hover x md-x lg-x y md-y lg-y"
                >
                  <div className="img-inner dark">
                    <img
                    style={{width:"100%"}}
                      src="img/Pro1.jpg"
                      className="attachment-large size-large"
                      alt="Amazing truck dispatching."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "rgb(0,0,0)",
        }}
      >
        <div className="bg section-bg fill bg-fill bg-loaded bg-loaded"></div>
        <div className="section-content relative">
          <div
            className="d-block d-lg-flex d-md-flex"
          >
            <div id="col-151993412" className="col-12 col-lg-6 col-md-6">
              <div className="col-inner">
                <div
                  className="img has-hover x md-x lg-x y md-y lg-y"
                  id="image_428260237"
                  >
                  <div className="img-inner dark">
                    <img
                  style={{ width: "100%" }}
                      src="img/Pro2.jpg"
                      className="attachment-large size-large"
                      alt="we can get you the best paying loads out of the midwest"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="col-1554792773" className="col-12 col-lg-6 col-md-6">
              <div className="p-5 mt-lg-5 text-center">
                <h2 className="text-center text-white">Drivers Are Our Priority</h2>
                <p className="text-center text-white">
                  We stand side by side with our truck drivers to plan out the
                  best working schedule that fits with their life. At Enable
                  Dispatch we take into account the personal life that needs
                  respected and like to consider our drivers as a part of the
                  family. Our drivers call the shots and are our top priority!
                </p>
                <a
                  href="mailto:support@enabledispatch.com"
                  target="_blank"
                  className="button mt-4"
                  rel="noopener"
                  style={{padding:"10px 20px" , border:"1px solid white" , textDecoration:"none"}}
                >
                  <span className="text-white fs-4 me-2">Contact Us</span>
                  <BsEnvelope color="white" size={25} className="mb-1" />
                  <i className="icon-envelop" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProDispatch;
