import './App.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Services from './pages/Services';
import { Route, Routes } from "react-router-dom";
import SetupCarrier from './pages/SetupCarrier';
import Terms from './components/Terms';

function App() {
  return (
   
 <Routes > 
    <Route path="/" element={<Home />} /> 
    <Route path="/whyenable" element={<About />} />
    <Route path="/services" element={<Services />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/setupCarrier" element={<SetupCarrier />} />
    <Route path="/terms-and-conditions" element={<Terms />} />

    </Routes>
    
  );
}

export default App;
